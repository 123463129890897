import { BaseMap } from './BaseMap.js';

export class Bing extends BaseMap {
  static initClass() {
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.Bing";

    window.initializeBing = () => {
      return this.providerCallback();
    };
  }

  constructor(args) {
    super(args);
    this.mapOptions = {
      credentials: this.config.apiID,
      zoom: this.config.zoom,
      disableZooming: this.config.disableMapControl,
      disablePanning: this.config.disableMapControl,
      showScalebar: !this.config.disableMapControl,
      showMapTypeSelector: false,
      showDashboard: !this.config.disableMapControl,
      enableSearchLogo: false
    };
  }

  appendScript() {
    let script = document.createElement('script');
    script.type = "text/javascript";
    script.src = "//ecn.dev.virtualearth.net/mapcontrol/mapcontrol.ashx?v=7.0&onScriptLoad=initializeBing&s=1";
    return document.body.appendChild(script);
  }

  preparePin(bingLoc, location, i) {
    let pin = new Microsoft.Maps.Pushpin(bingLoc, {
      icon: this.iconImage(location, i),
      height: "37px",
      width: "26px",
      anchor: new Microsoft.Maps.Point(13, 37)
    }
    );
    pin.locationData = location;
    return pin;
  }

  prepareMap() {
    this.mapOptions.center = new Microsoft.Maps.Location(this.allLocations[0].latitude, this.allLocations[0].longitude);
    this.mapOptions.mapTypeId = Microsoft.Maps.MapTypeId.road;

    let pinLayer = new Microsoft.Maps.EntityCollection();
    let map = new Microsoft.Maps.Map(this.element, this.mapOptions);
    let options = map.getOptions();
    let locations = [];

    let index = 0;
    for (let location of Array.from(this.allLocations)) {
      let loc = new Microsoft.Maps.Location(location.latitude, location.longitude);
      let pin = this.preparePin(loc, location, index);
      pinLayer.push(pin);
      locations.push(loc);

      index++;
      if (index > 100) {
        break;
      }

      map.setView({bounds: Microsoft.Maps.LocationRect.fromLocations(locations)});
      map.entities.push(pinLayer);
    }

    Microsoft.Maps.Events.addHandler(map, 'click', e => {
      if (e.targetType === 'pushpin') {
        return this.clickHandler(e.target.locationData);
      }
    });
    return map;
  }
}

Bing.initClass();
