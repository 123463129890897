export class RunIfVisible {

  static runIfTargetVisible(target, thingToRun, options = {}) {
    if (!target) {
      return;
    }

    if (!('IntersectionObserver' in window)) {
      thingToRun();
      return;
    }

    const ObserveOnce = (obs, entries) => {
      entries.forEach((entry) => {
        if(!entry.isIntersecting) return;
        obs.disconnect();
        thingToRun();
      });
    }

    const observer = new IntersectionObserver((entries) => {
      ObserveOnce(observer, entries);
    }, options);

    observer.observe(target);
  }

  static lazyLoadImages(selector = "", options = {}) {
    for (let el of document.querySelectorAll(`${selector}[data-src]`)) {
      this.runIfTargetVisible(el, () => {
        el.src = el.dataset.src;
      }, options);
    }
    for (let el of document.querySelectorAll(`${selector}[data-srcset]`)) {
      this.runIfTargetVisible(el, () => {
        el.srcset = el.dataset.srcset;
      }, options);
    }
    for (let el of document.querySelectorAll(`${selector}[data-bg]`)) {
      this.runIfTargetVisible(el, () => {
        el.style.backgroundImage = el.dataset.bg;
      }, options);
    }
  }
}
