import { GoogleMapsEnterprise } from './GoogleMapsEnterprise.js';

export class GoogleMapsFree extends GoogleMapsEnterprise {
  static initClass() {
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.GoogleMapsFree";
  }
  constructor(args) {
    super(args);
    this.versionType = 'key';
  }
}

GoogleMapsFree.initClass();
