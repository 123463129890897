export class LocatorEvent {
  constructor(id, opts) {
    let details = {
      yextId: id
    };

    Object.assign(details, opts);
    return new CustomEvent(this.constructor.eventTypeName, {detail: details});
  }
}

export class PinClickEvent extends LocatorEvent {
  static initClass() {
    this.eventTypeName = 'pin-click';
  }
}

PinClickEvent.initClass();

export class CardClickEvent extends LocatorEvent {
  static initClass() {
    this.eventTypeName = 'card-click';
  }
}

CardClickEvent.initClass();

export class CardHoverEvent extends LocatorEvent {
  static initClass() {
    this.eventTypeName = 'card-hover';
  }

  constructor(id, on) {
    if (on == null) {
      on = false;
    }
    super(id, {active: on});
  }
}

CardHoverEvent.initClass();

export class PinHoverEvent extends LocatorEvent {
  static initClass() {
    this.eventTypeName = 'pin-hover';
  }

  constructor(id, on) {
    if (on == null) {
      on = false;
    }
    super(id, {active: on});
  }
}

PinHoverEvent.initClass();
