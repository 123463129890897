/**
 * 
 * @param element 
 */
export function isVisible (element: HTMLElement): boolean {
  return !isHidden(element);
};

/**
 * more info: https://stackoverflow.com/questions/19669786/check-if-element-is-visible-in-dom
 * @param element 
 */
export function isHidden (element: HTMLElement): boolean {
  if (element == null) return true;
  return element.offsetParent === null;
}