import { AccessibilityHelpers } from 'js/components/Util/Accessibility.js';

let accessibilityHelpersInstance = new AccessibilityHelpers();

export class Dropdown {
  constructor(toggle) {
    this.toggle = toggle;
    this.number = toggle.classList[2].split('-')[2];
    this.dropdownWrappers = document.querySelectorAll('.js-dropdownWrapper');
    this.dropdownWrapper = document.querySelector(`.js-dropdownWrapper-${this.number}`);
    this.dropdown = document.querySelector('.js-headerDropdown-'+this.number);
    this.media = window.matchMedia(`(min-width: 768px)`);
    this.headerEl = document.getElementById('desktopHeader');
    this.overlayEl = document.getElementById('Header-overlay');
    this.bodyEl = document.body;

    this.toggle.addEventListener('click', () => {
      if (this.isOpen()) {
        this.collapse();
      } else {
        this.expand();
      }
    });

    this.toggle.addEventListener('mouseover', () => {
      if (!this.isOpen() && this.dropdownWrapper && this.dropdownWrapper.classList.contains('is-dropdown-expanded')) {
        this.expand();
      }
    });
  }

  onCollapse(func) {
    this.collapseCB = func;
  }

  isOpen() {
    return this.dropdown.classList.contains('is-open');
  }

  isDesktop() {
    return this.media.matches;
  }

  isMobile() {
    return !this.isDesktop();
  }

  expand() {
    this.closeAll();
    this.dropdown.classList.add('is-open');
    this.headerEl.classList.add('isOpen');
    this.bodyEl.classList.add('disable-scroll');
    this.dropdownWrappers.forEach((el) => {
      el.classList.remove('is-dropdown-unexpanded');
      el.classList.add('is-dropdown-expanded');
      el.classList.remove('is-dropdown-open');
    });
    if (this.dropdownWrapper) {
      this.dropdownWrapper.classList.add('is-dropdown-open');
    }
    this.overlayEl.addEventListener('click', () => {
      this.collapse();
    });
  }

  collapse() {
    this.closeAll();
    this.dropdown.classList.remove('is-open');
    this.headerEl.classList.remove('isOpen');
    this.bodyEl.classList.remove('disable-scroll');
    this.dropdownWrappers.forEach((el) => {
      el.classList.add('is-dropdown-unexpanded');
      el.classList.remove('is-dropdown-expanded');
      el.classList.remove('is-dropdown-open');
    });
  }

  closeAll() {
    let all_dropdowns = document.querySelectorAll('.js-headerDropdown');
    all_dropdowns.forEach(dropdown => {
      dropdown.classList.remove('is-open');
    });
  }
}
