import { BaseMap } from './BaseMap.js';

export class MapQuest extends BaseMap {
  static initClass() {
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.MapQuest";
  }
  constructor(args) {
    super(args);
    this.mapOptions = {
      elt: this.config.mapId,
      zoom: this.config.zoom,
      mtype: 'map',
      bestFitMargin: 100,
      zoomOnDoubleClick: !this.config.disableMapControl
    };
  }

  appendScript() {
    let endpoint = '//www.mapquestapi.com/sdk/js/v7.2.s/mqa.toolkit.js?key='; // Enterprise endpoint
    if (this.config.apiID === 'Fmjtd%7Cluu829urnh%2Cbn%3Do5-9w1ghy') {
      endpoint = '//open.mapquestapi.com/sdk/js/v7.2.s/mqa.toolkit.js?key='; // Developer "Open" endpoint
    }
    let mapScript = document.createElement('script');
    mapScript.src = `${endpoint}${this.config.apiID}`;
    mapScript.onload = () => {
      this.constructor.providerCallback();
    };
    document.head.appendChild(mapScript);
  }

  preparePin(loc, i) {
    let pin = new MQA.Poi({
      lat: loc.latitude,
      lng: loc.longitude
    });

    MQA.EventManager.addListener(pin, 'click', evt => {
      return this.clickHandler(loc, pin, i);
    });
    let icon = new MQA.Icon(this.iconImage(loc, i), 26, 37);
    pin.setIcon(icon);
    return pin;
  }

  prepareMap() {
    let sc = new MQA.ShapeCollection();
    let index = 0;
    for (let location of Array.from(this.allLocations)) {
      index++;
      let pin = this.preparePin(location, index);
      sc.add(pin);
    }

    if (this.allLocations.length > 0) {
      this.mapOptions.collection = sc;
    }

    let map = new MQA.TileMap(this.mapOptions);
    map.setDraggable(!this.config.disableMapControl);
    map.setZoomLevel(this.config.zoom);

    if (this.allLocations.length > 1) {
      map.bestFit(true, 2, 18); // [2 - 18] range is [min - max] zoom level allowed.
    }

    if (this.allLocations.length === 0) {
      map.setCenter({lat: this.latitude, lng: this.longitude}); // display map of American continent.
      map.setZoomLevel(2);
    }

    if (!this.config.disableMapControl) {
      MQA.withModule('smallzoom', () =>
        map.addControl(
          new MQA.SmallZoom(),
          new MQA.MapCornerPlacement(MQA.MapCorner.TOP_LEFT, new MQA.Size(5,5))
        )
      );
    }
    return map;
  }
}

MapQuest.initClass();
