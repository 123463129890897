export class HeightToggler {
  constructor (minHeight = 0) {
    this.minHeight = minHeight;
  }

  collapseElement(el) {
    const currentHeight = el.scrollHeight;
    const elementTransition = el.style.transition;
    el.style.transition = '';

    requestAnimationFrame(() => {
      el.style.height = `${currentHeight}px`;
      el.style.transition = elementTransition;

      requestAnimationFrame(() => {
        el.style.height = `${this.minHeight}px`;
      });
    });
  }

  expandElement(el) {
    const currentHeight = el.scrollHeight;
    el.style.height = `${currentHeight}px`;

    function func (event) {
      el.removeEventListener('transitionend', func);
    };

    el.addEventListener('transitionend', func);
  };
}
