export class BackToTop {
  constructor() {
    const backToTopButton = document.querySelector(".js-back-to-top");
    if (backToTopButton) {
      backToTopButton.addEventListener("click", () => this.scrollToTop());
      document.addEventListener("scroll", () => {
        if (document.documentElement.scrollTop > window.innerHeight) {
          backToTopButton.classList.remove("hide-button");
        } else {
          backToTopButton.classList.add("hide-button");
        }
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
}