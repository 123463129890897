import 'babel-polyfill';
import 'script-loader!node_modules/jquery/dist/jquery.min.js';
import URI from 'urijs';

// uncomment if you need to do expand/collapse:
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/collapse.js';
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/transition.js';

import { Global } from 'js/common/global.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { Yext } from 'js/components/Soy2js/Soy2js.js'; // NOTE: if not using soy2js, replace with window.Yext = window.Yext || {};

import { Cookies } from 'js/components/Cookies/index.js';
import { GoogleAdwords } from 'js/components/GoogleAdwords/index.js';
import { GoogleAnalytics } from 'js/components/GoogleAnalytics/index.js';
import { Image } from 'js/components/Image/index-override.js';
import { LocationHours } from 'js/components/LocationHours/index.js';
import { Modal } from 'js/components/Modal/Modal.js';
import { EmailModal } from 'js/common/modules/EmailModal.js'
import { Accordion } from 'js/common/modules/Accordion.js';

import * as helpers from 'js/location/helpers.js';

import { ProductDetails } from 'js/location/modules/ProductDetails.js';
import { RenderHoursByAppt } from 'js/location/modules/HoursByAppt.js';
import { Maps } from 'js/components/Maps/index.js';
import { Instance as MapsAPI } from 'js/components/Maps/GoogleMapsAPI.js';
import { SearchMap, MapSetup } from 'js/search/modules/Google.js';
import { BackToTop } from './common/modules/BackToTop';

require('script-loader!.tmp/js/templates/common/modules/Forms.js');
require('script-loader!.tmp/js/templates/components/svg/icons.js');
require('script-loader!.tmp/js/templates/components/Modal/Modal-override.js');
require('script-loader!.tmp/js/templates/location/helpers.js');

Global.init();
GoogleAdwords.init();
GoogleAnalytics.enableAutotracking('yext');


OnReady(() => {
  const isWriteReviewPage = document.querySelector('#js-write-review') ? true : false;
  if (!isWriteReviewPage) {
    LocationHours.loadAndRun();
    ProductDetails.init();
    RenderHoursByAppt();
    helpers.setUpTabs();
    helpers.setUpSmoothScroll();
    helpers.setUpAgentAboutGrid();
    (new EmailModal).init();
  }
  Accordion.init('.js-accordion');

  Maps.loadAndRun();
  MapsAPI.load();

  let links = document.getElementsByTagName("a");
  for (let link of links) {
    if (!link.href.includes('agency.nationwide.com')) {
      link.setAttribute('target', '_blank');
    }
  }

  // Open product section for anchor links
  const windowUrl = window.location.href;
  if (windowUrl.includes('#')) {
    let anchorLink = windowUrl.slice(windowUrl.lastIndexOf('#'));
    for (const link of document.querySelectorAll('.js-product-link[href^="#"]')) {
      if (link.getAttribute('href') == anchorLink) {
        link.click();
        document.querySelector(link.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  }

  // - The un-minified file is different than the minified one :/
  // - Uncomment the following if using JVector map
  // VectorMap.autorun();
});
