import { BaseMap } from './BaseMap.js';
import { Bing } from './Bing.js';
import { GoogleMapsEnterprise } from './GoogleMapsEnterprise.js';
import { GoogleMapsFree } from './GoogleMapsFree.js';
import { Mapbox } from './Mapbox.js';
import { MapboxGL } from './MapboxGL.js';
import { MapQuest } from './MapQuest.js';
import { LeafletBase } from './LeafletBase.js';
import { MapQuestLeaflet } from './MapQuestLeaflet.js';

export class Maps {
  static initClass() {
    this.BaseMap = BaseMap;
    this.Bing = Bing;
    this.GoogleMapsEnterprise = GoogleMapsEnterprise;
    this.GoogleMapsFree = GoogleMapsFree;
    this.Mapbox = Mapbox;
    this.MapboxGL = MapboxGL;
    this.MapQuest = MapQuest;
    this.MapQuestLeaflet = MapQuestLeaflet;
    this.FactoryForProvider = {
      BaseMap: data => new BaseMap(data),
      Google: data => new GoogleMapsEnterprise(data),
      'Google-Free': data => new GoogleMapsFree(data),
      Bing: data => new Bing(data),
      MapQuest: data => new MapQuest(data),
      'MapQuest Leaflet': data => new MapQuestLeaflet(data),
      Mapbox: data => new Mapbox(data),
      MapboxGL: data => new MapboxGL(data),
    };
  }

  static loadMapData(scope) {
    let queryScope = scope || document;
    let instances = [];
    for (let config of queryScope.querySelectorAll('.js-map-config')) {
      let rawData = config.innerText;
      if (rawData == null) {
        continue;
      }
      let data = JSON.parse(rawData);
      if (data == null) {
        continue
      }
      let targets = document.querySelectorAll(`[id=${data.config.mapId}]`);
      if (targets.length > 1) {
        console.error(`More than one map with id: ${data.config.mapId}, mapIDs must be unique`);
        continue;
      } else if (targets.length == 0) {
        console.error(`No map elements found with id #${data.config.mapId}`);
        continue;
      }
      let target = targets[0];
      data.element = target;
      let factory = this.FactoryForProvider[data.config.provider];
      if (factory == null) {
        console.error(`No factory method found for for ${data.config.provider}`);
        continue;
      }
      target.yextMap = factory(data);
      instances.push(target.yextMap);
    }

    queryScope.dispatchEvent(new CustomEvent('Yext.Maps.DataLoaded', {instances}));

    this.instances = instances;
    return instances;
  }

  static loadAndRun(scope) {
    for (let map of this.loadMapData()) {
      map.appendProviderScripts();
    }
  }
}

Maps.initClass();
