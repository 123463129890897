import { LeafletBase } from './LeafletBase.js';

export class MapQuestLeaflet extends LeafletBase {
  static initClass() {
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.MapQuestLeaflet";
  }

  appendScript() {
    let leafletBaseUrl = "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.5/leaflet";
    let link = document.createElement('link');
    let mapScriptBase = document.createElement('script');
    let mapScriptApi = document.createElement('script');
    link.rel = 'stylesheet';
    link.href = `${leafletBaseUrl}.css`;
    mapScriptBase.src = `${leafletBaseUrl}.js`;
    mapScriptBase.onload = () => {
      mapScriptApi.src = `https://www.mapquestapi.com/sdk/leaflet/v2.s/mq-map.js?key=${this.config.apiID}`;
      mapScriptApi.onload = () => {
        this.constructor.providerCallback();
      }
      document.head.appendChild(mapScriptApi);
    };
    document.body.appendChild(link);
    document.head.appendChild(mapScriptBase);
  }

  initializeMap() {
    let map;
    return map = L.map(this.config.mapId, $.extend(this.mapOptions,
      {layers: MQ.mapLayer()})
    );
  }
}

MapQuestLeaflet.initClass();
