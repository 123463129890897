/*
 * Hours by appointment and evening hours by appointment are given as arrays of days,
 * e.g. ['Monday', 'Wednesday', 'Thursday']. Depending on the input, we need to output text
 * based on the following rules:
 * 1. If there is an isolated day, i.e. when the days immediately preceding it and immediately
 * following it are not present, then print the day by itself
 * 2. If there are two or more consecutive days, then print them as '<first day>-<last day>'
 * In the input, Sunday comes first if it is present. However, since Sunday is more often grouped
 * with Saturday, we sort the input such that it comes last
 *
 * EXAMPLES
 *
 * Input: $customByName['By Appt'] = ['Monday', 'Tuesday', 'Thursday', 'Friday', 'Saturday']
 * Output: Mon-Tue and Thu-Sat by Appointment Only
 *
 * Input: $customByName['Evening by appt'] = ['Sunday', 'Monday', 'Wednesday', 'Friday']
 * Output: Monday, Wednesday, Friday, and Sunday Evening by Appointment Only
 *
 * Input: $customByName['Evening by appt'] = ['Saturday']
 * Output: Saturday Evening by Appointment Only
 *
 * Input: $customByName['ByAppt'] = ['Tuesday', 'Wednesday', 'Thursday']
 * Output: Tue-Thu by Appointment Only
 *
 * Input: $customByName['ByAppt'] = ['Sunday', 'Monday', 'Thursday', 'Friday']
 * Output: Monday, Thu-Fri, and Sunday by Appointment Only
 */
export const RenderHoursByAppt = () => {
  const hoursDataEl = document.querySelector('#js-appt-data');
  const hoursData = hoursDataEl ? JSON.parse(hoursDataEl.innerHTML) : {};
  const daysToIntMap = {
    'Monday': 1,
    'Tuesday': 2,
    'Wednesday': 3,
    'Thursday': 4,
    'Friday': 5,
    'Saturday': 6,
    'Sunday': 7
  }
  const daysByAppt = hoursData.byAppt ? hoursData.byAppt.map(day => daysToIntMap[day]) : [];
  const eveningsByAppt = hoursData.eveningByAppt ? hoursData.eveningByAppt.map(day => daysToIntMap[day]) : [];
  const daysByApptStr = getDaysString(daysByAppt);
  const eveningsByApptStr = getDaysString(eveningsByAppt);
  if (daysByApptStr) {
    document.querySelector('.js-hours-appt').innerHTML = `${daysByApptStr} by Appointment Only`;
  }
  if (eveningsByApptStr) {
    document.querySelector('.js-hours-appt-evening').innerHTML = `${eveningsByApptStr} Evening by Appointment Only`;
  }
};

/*
 * Takes an array of integers corresponding with days of the week and returns a 
 * string of days following preset rules
 *
 * @input Array, e.g. [7, 1, 2, 3, 5]
 * @output String, e.g. 'Mon-Wed, Friday, and Sunday'
 */
const getDaysString = (daysArr) => {
  if (daysArr.length === 0) return;
  daysArr.sort();
  return joinConsecutiveDays(groupConsecutiveDays(daysArr));
};

/*
 * Returns an array of strings grouped by consecutive days
 *
 * @input Array, e.g. [1, 2, 3, 5, 7]
 * @output Array, e.g. ['Mon-Wed', 'Friday', 'Sunday']
 */
const groupConsecutiveDays = (daysArr) => {
  const intToDaysMap = [null, 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const intToDaysMapLong = [null, 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const result = [];
  let firstDay = 0;
  for (let i = 0; i < daysArr.length; i++) {
    const isLastDay = i === daysArr.length - 1;
    if (isLastDay || daysArr[i + 1] - daysArr[i] > 1) {
      if (firstDay === i) {
        const dayIdx = daysArr[i];
        result.push(intToDaysMapLong[dayIdx]);
      } else {
        const firstIdx = daysArr[firstDay];
        const lastIdx = daysArr[i];
        result.push(`${intToDaysMap[firstIdx]}-${intToDaysMap[lastIdx]}`);
      }
      firstDay = i + 1;
    }
  }
  return result;
}

/*
 * Takes an array of strings grouped by consecutive days and returns a formatted
 * string containing each of the strings from the input array
 *
 * @input Array, e.g. ['Mon - Wed', 'Friday', 'Sunday']
 * @output String, e.g. 'Mon - Wed, Friday, and Sunday'
 */
const joinConsecutiveDays = (days) => {
  if (days.length === 1) {
    return days[0];
  } else if (days.length === 2) {
    return `${days[0]} and ${days[1]}`;
  } else {
    return `${days.slice(0, -1).join(', ')}, and ${days.slice(-1)}`;
  }
};

