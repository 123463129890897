import { HeightToggler } from 'js/common/modules/HeightToggler.js';

export class ProductDetails {
  static init() {
    const minHeight = 250;
    const heightToggler = new HeightToggler(minHeight);

    const descWrappers = document.querySelectorAll('.js-desc-wrapper');

    for (const descWrapper of descWrappers) {
      const toggleButton = descWrapper.querySelector('.js-desc-toggle-button');
      const desc = descWrapper.querySelector('.js-desc');
      const descOverlay = descWrapper.querySelector('.js-desc-overlay');
      toggleButton.addEventListener('click', () => {
        if (descWrapper.classList.toggle('js-read-more')) {
          heightToggler.expandElement(desc);
          descOverlay.style.display = 'none';
        } else {
          heightToggler.collapseElement(desc);
          descOverlay.style.display = 'block';
        }
      });
    }
  }
}

