import { AccessibilityHelpers } from 'js/components/Util/Accessibility.js';
import { OptimizedResizeInstance } from 'js/components/Util/OptimizedResize.js';
import { Dropdown } from 'js/common/modules/Dropdown.js';
const createFocusTrap = require('focus-trap/index.js');

let accessibilityHelpersInstance = new AccessibilityHelpers();

export class Header {
  constructor(mobileBP = 884) {
    this.bodyEl = document.body;
    this.headerEl = document.getElementById('mobileHeader');
    this.menuOverlayEl = this.headerEl.querySelector('.Header-overlay');
    this.menuEl = this.headerEl.querySelector('.Header-menu');
    this.menuLinks = this.headerEl.querySelectorAll('.Header-mobileLink');
    this.menuBtnEl = this.headerEl.querySelector('.Header-toggleIcon');
    this.hasBeenSetupMobile = false;
    this.mobileBP = window.matchMedia(`(max-width: ${mobileBP}px)`);
    this.wasDesktop = !this.mobileBP.matches;
    this.focusTrap = createFocusTrap('#Header', {
      onActivate: () => {
        this.handleHeaderTabIndexes(0);
      },
      onDeactivate: () => {
        this.handleHeaderTabIndexes(-1);
      },
    });

    this.handleMobileHeader();
    this.mobileBP.addListener(this.handleMobileHeader.bind(this));
    this.setupDropdowns();
    this.slidingMenus();
  }

  handleMobileHeader() {
    if (this.mobileBP.matches) {
      this.enableMobileHeader();
    } else {
      this.resetMobileHeader();
    }
  }

  enableMobileHeader() {
    const headerLogic = () => {
      if (this.headerEl.classList.toggle('isOpen')) {
        this.bodyEl.classList.add('disable-scroll');
        this.focusTrap.activate();
      } else {
        this.bodyEl.classList.remove('disable-scroll');
        this.focusTrap.deactivate();
      }

      accessibilityHelpersInstance.toggleAriaState(this.menuEl, 'hidden');
      accessibilityHelpersInstance.toggleAriaState(this.menuBtnEl, 'expanded');
      accessibilityHelpersInstance.toggleAriaState(this.menuOverlayEl, 'expanded');
      accessibilityHelpersInstance.toggleAriaState(this.menuOverlayEl, 'hidden');
    };

    this.handleHeaderTabIndexes(-1);
    accessibilityHelpersInstance.setAriaProp(this.menuEl, 'hidden', true);

    if (!this.hasBeenSetupMobile) {
      this.menuBtnEl.addEventListener('click', headerLogic);
      this.menuOverlayEl.addEventListener('click', headerLogic);
      this.hasBeenSetupMobile = true;
    }
    this.wasDesktop = false;
  }

  resetMobileHeader() {
    if (!this.wasDesktop) {
      this.headerEl.classList.remove('isOpen');
      accessibilityHelpersInstance.setAriaProp(this.menuEl, 'hidden', false);
      this.focusTrap.deactivate();
      this.handleHeaderTabIndexes(0);
      this.wasDesktop = true;
    }
  }

  handleHeaderTabIndexes(tabIndex) {
    accessibilityHelpersInstance.setTabIndex(this.menuLinks, tabIndex);
    accessibilityHelpersInstance.setTabIndex(this.menuOverlayEl, tabIndex);
  }

  setupDropdowns() {
    const dropdowns = document.querySelectorAll('.js-dropdownToggle');
    for (const dropdown of dropdowns) {
      new Dropdown(dropdown);
    }
  }

  slidingMenus() {
    let firstLevel = document.querySelectorAll('.js-top-level > li > a');
    let secondWrapper = document.querySelector(".js-menu:nth-child(2)");
    let secondSubmenu = secondWrapper.querySelector(".js-sub-wrapper");
    let backButtons = document.querySelectorAll(".js-header-button");

    for (const menuLink of firstLevel) {
      menuLink.addEventListener("click", function(event) {
        const siblings = menuLink.nextElementSibling;
        if (siblings) {
          event.preventDefault();
          const siblingClone = siblings.cloneNode(true);
          secondSubmenu.innerHTML = "";
          secondSubmenu.append(siblingClone);
          secondWrapper.classList.add("is-visible");
          const prevText = menuLink.innerHTML;
          const prevButtonEl = document.querySelector('.js-header-button');
          if (prevButtonEl) {
            prevButtonEl.innerHTML = `<span>${prevText}</span>`
          }
        }
      });
    }

    for (const backButton of backButtons) {
      backButton.addEventListener("click", function() {
        const parent = this.closest(".js-menu");
        parent.classList.remove("is-visible");
        parent.previousElementSibling.querySelector(".is-active").classList.remove("is-active");
      });
    }
  }
}
