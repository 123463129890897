const createFocusTrap = require('focus-trap/index.js');
import { AccessibilityHelpers } from 'js/components/Util/Accessibility.js';

let accessibilityHelpersInstance = new AccessibilityHelpers();

export class Modal {
  constructor(modal, openByDefault) {
    this.modal = modal;

    let closeButtons = this.modal.querySelectorAll('.c-Modal-closeButton');
    let focusTrap = createFocusTrap(this.modal)

    let config = this.modal.dataset;

    const openButtons = config.openButtons.split(" ");

    for (const button of openButtons) {
      let buttons = document.getElementsByClassName(button);
      for (const openButton of buttons) {
        openButton.addEventListener('click', () => {
          this.openModal(focusTrap, config);
        });
      }
    }

    for (const button of closeButtons) {
      button.addEventListener('click', () => {
        this.modal.classList.remove('c-Modal-open');
        focusTrap.deactivate();
        if (config.overlay) {
          this.modal.classList.remove('c-Modal-overlay');
        }
      });
    }

    if (config.closeOnOutsideClick) {
      this.modal.addEventListener('click', (event) => {
        if (event.target == this.modal) {
          focusTrap.deactivate();
          this.modal.classList.remove('c-Modal-open');
        }
      });
    }
    if (openByDefault) this.openModal(focusTrap, config);
  }

  static initModals(modalIdentifier = '.js-Modal') {
    let modalArray = document.querySelectorAll(modalIdentifier);

    for (const modal of modalArray) {
      new Modal(modal, false);
    }
  }

  addCallbacks(open, close) {
    let modalArray = document.querySelectorAll('.js-Modal');

    for (const modal of modalArray) {
      let config = modal.dataset;
      let openButtons = config.openButtons.split(" ");
      let closeButtons = modal.querySelectorAll('.c-Modal-closeButton');

      for (const button of openButtons) {
        let buttons = document.getElementsByClassName(button);
        for (const openButton of buttons) {
          openButton.addEventListener('click', () => {open(modal)});
        }
      }

      for (const button of closeButtons) {
        button.addEventListener('click', () => {close(modal)});
      }

      if (config.closeOnOutsideClick) {
        modal.addEventListener('click', (event) => {
          if (event.target == modal) {
            close(modal);
          }
        });
      }
    }
  }

  openModal(focusTrap, config) {
    this.modal.classList.add('c-Modal-open');
    focusTrap.activate();
    if (config.overlay) {
      this.modal.classList.add('c-Modal-overlay');
    }
  }
}
