import { LeafletBase } from './LeafletBase.js';

export class Mapbox extends LeafletBase {
  static initClass() {
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.Mapbox";
    this.version = "v3.1.1";
  }

  appendScript() {
    let mapboxBaseUrl = `https://api.tiles.mapbox.com/mapbox.js/${this.constructor.version}/mapbox`;
    let link = document.createElement('link');
    let mapScript = document.createElement('script');
    link.rel = 'stylesheet';
    link.href = `${mapboxBaseUrl}.css`;
    mapScript.src = `${mapboxBaseUrl}.js`;
    mapScript.onload = () => {
      L.mapbox.accessToken = this.config.apiID;
      return this.constructor.providerCallback();
    };
    document.head.appendChild(link);
    document.head.appendChild(mapScript);
  }

  initializeMap() {
    let map;
    return map = L.mapbox.map(this.config.mapId, this.config.mapboxMapIdentifier, this.mapOptions);
  }
}

Mapbox.initClass();
