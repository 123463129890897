/**
 * Insert script element into HTML from provided src url
 */

export const LoadScript = function(src) {
  const someScript = document.getElementsByTagName('script')[0];
  const script = document.createElement('script');
  script.async = 1;
  script.src = src;
  someScript.parentNode.insertBefore(script, someScript);
}
