/*
 * Contains helper functions used in the location script
 */

import Masonry from 'masonry-layout';
import URI from 'urijs';


/*
 * Sets up the tabs in the agency 'About' section
 * Tabs have IDs of the form "id='agency-about-staff'", "id='agency-about-history'", etc.
 * while tab buttons have data-ids of the form "data-id='agency-about-staff'".
 * The data-id of a given tab button is equal to the id of the tab associated with that button
 */
export const setUpTabs = () => {
  const tabButtons = document.querySelectorAll('.js-tab-button');
  const tabs = document.querySelectorAll('.js-tab-content');

  for (const button of tabButtons) {
    button.addEventListener('click', () => {
      switchTabs(button, tabButtons, tabs);
      scrollToButton(button);
    });
  }
};

const switchTabs = (tabButton, tabButtons, tabs) => {
  const targetTabId = `#${tabButton.dataset['id']}`;
  for (const tab of tabs) {
    tab.style.display = 'none';
  }
  for (const checkButton of tabButtons) {
    checkButton.disabled = false;
  }
  document.querySelector(targetTabId).style.display = 'flex';
  tabButton.disabled = true;
};

const scrollToButton = (tabButton) => {
  const scrollingParent = document.querySelector('.js-scrollable-container');
  scrollingParent.scrollLeft = tabButton.offsetLeft - 90;
};


/*
 * If the page is a location page, then we display reviews on the page. If the page
 * is a 'Write Review' category page, we want to redirect back to the location page
 * if the user did not get to this page by clicking a 'Write a review' button.
 * This is necessary because clicking a 'Write a review' button redirects to the 'Write Review' page
 * while passing a bunch of URL params that Bazaarvoice needs in order to render the page content.
 * Just going to something like https://agency.nationwide.com/[location page URL]/write-review would
 * result in a blank page. If the query parameters do exist, then we render the review submission form.
 */
export const setUpBVReviews = () => {
  const bvConfigEl = document.querySelector('#js-bv-config');
  const config = bvConfigEl ? JSON.parse(bvConfigEl.innerHTML) : {};
  const baseUrl = getBaseUrl();
  if (bvConfigEl) {
    if (config.isSubmissionPage) {
      redirectIfNecessary(() => {
        $BV.ui("submission_container", {
          onEvent: (json) => {
            if (json.eventSource === 'Display' && json.eType === 'Write') {
              const writeReview = document.querySelector('#js-write-review');
              const previewButton = writeReview.querySelector('#BVRRPreviewReviewButtonID');
              const submitButton = writeReview.querySelector('#BVRRSubmitReviewButtonID');

              if (previewButton) {
                previewButton.setAttribute('data-ya-track', 'preview');
              }
              if (submitButton) {
                submitButton.setAttribute('data-ya-track', 'submit_review');
              }
            }
          }
        })
      });
    } else {
      $BV.configure("global", {
        submissionContainerUrl: `${baseUrl}/${config.submissionContainerUrl}`
      });
      $BV.ui('rr', 'show_reviews', {
        productId: `${config.id}`,
        onEvent: (json) => {
          if (json.eventSource === 'Display' && json.eType === 'Read') {
            const nap = document.querySelector('.NAP');
            const reviews = document.querySelector('.BVReviews');

            // Set Yext analytics for Bazaarvoice NAP ratings summary links

            const napWriteReviewButton = nap.querySelector('#BVRRRatingSummaryLinkWriteID') ? nap.querySelector('#BVRRRatingSummaryLinkWriteID').querySelector('a') : null;
            const napReadReviewsLink = nap.querySelector('#BVRRRatingSummaryLinkReadID') ? nap.querySelector('#BVRRRatingSummaryLinkReadID').querySelector('a') : null;
            const napWriteFirstButton = nap.querySelector('#BVRRRatingSummaryLinkWriteFirstID') ? nap.querySelector('#BVRRRatingSummaryLinkWriteFirstID').querySelector('a') : null;

            if (napWriteReviewButton) {
              napWriteReviewButton.setAttribute('data-ya-track', 'write_review');
            }
            if (napWriteFirstButton) {
              napWriteFirstButton.setAttribute('data-ya-track', 'write_review');
            }
            if (napReadReviewsLink) {
              napReadReviewsLink.setAttribute('data-ya-track', 'read_reviews');
            }

            // Set Yext analytics for Bazaarvoice reviews section links

            const reviewsWriteReviewButton = reviews.querySelector('#BVRRRatingSummaryLinkWriteID') ? reviews.querySelector('#BVRRRatingSummaryLinkWriteID').querySelector('a') : null;
            const reviewsWriteFirstButton = reviews.querySelector('#BVRRDisplayContentNoReviewsID') ? reviews.querySelector('#BVRRDisplayContentNoReviewsID').querySelector('a') : null;
            const reviewsSocialLinks = reviews.querySelector('.BVRRReviewSocialLinksContainer') ? reviews.querySelector('.BVRRReviewSocialLinksContainer').querySelectorAll('a') : null;

            if (reviewsWriteReviewButton) {
              reviewsWriteReviewButton.setAttribute('data-ya-track', 'write_review');
            }
            if (reviewsWriteFirstButton) {
              reviewsWriteFirstButton.setAttribute('data-ya-track', 'write_review');
            }
            for (const reviewThumbsUp of reviews.querySelectorAll('.BVDI_FVPositive')) {
              const reviewThumbsUpLink = reviewThumbsUp.querySelector('a');
              if (reviewThumbsUpLink) {
                reviewThumbsUpLink.setAttribute('data-ya-track', 'thumbs_up');
              }
            }
            for (const reviewReport of reviews.querySelectorAll('.BVDI_FVReportLinkInappropriate')) {
              const reviewReportLink = reviewReport.querySelector('a');
              if (reviewReportLink) {
                reviewReportLink.setAttribute('data-ya-track', 'report_inappropriate');
              }
            }
            if (reviewsSocialLinks) {
              for (const socialLink of reviewsSocialLinks) {
                socialLink.setAttribute('data-ya-track', 'social_link');
              }
            }
          }
        }
      });
    }
  }
}

const redirectIfNecessary = (callback) => {
  const redirectToLocPage = (url) => {
    url.pathname(url.directory(true));
    url.search('');
    window.location.replace(url.href());
  };

  const url = new URI(window.location.href);
  const hasBVParams = url.hasQuery('bvappcode');
  if (hasBVParams) {
    callback();
  } else {
    redirectToLocPage(url);
  }
};

/*
 * Sets up smooth scroll for each jump from the product teasers section to the
 * corresponding product details section
 * Opens product info section for anchor links
 */
export const setUpSmoothScroll = () => {
  const addSmoothScroll = (link) => {
    if (link) {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        document.querySelector(link.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      });
    }
  };
  for (const link of document.querySelectorAll('.js-product-link[href^="#"]')) {
    addSmoothScroll(link);
    link.addEventListener('click', (e) => {
      e.preventDefault();
      let container = document.querySelector('.js-product-container');
      if (container && (container.ariaExpanded == 'false')) {
        container.querySelector('.js-accordion-btn').click();
      }
      link.click();
    });
  }
  addSmoothScroll(document.querySelector('.js-product-details-link'));
};

/*
 * Implements the agent about section as a cascading grid using the
 * Masonry library
 */
export const setUpAgentAboutGrid = () => {
  const grid = document.querySelector('.js-about-grid');
  if (grid) {
    const masonry = new Masonry(grid, {
      itemSelector: '.js-about-grid-item',
      percentPosition: true,
      gutter: 30
    });
  }
};


const getBaseUrl = () => {
  if (window.location.origin) {
    return window.location.origin;
  }
  return `${window.location.protocol}//${window.location.host}`;
};
