import "sass/components/Image/Image-override.scss";

export function ImageObjectFit() {
  // this test will catch all browsers that don't support any of the values for object-fit
  if (!('CSS' in window && CSS.supports('object-fit', 'cover'))) {
    for (const image of document.querySelectorAll('[data-object-fit]')) {
      const parentEl = image.parentNode;
      const src = image.src || image.dataset.src;
      if (src) {
        const wrapper = document.createElement('div');
        for (let className of image.classList) {
          image.classList.remove(className);
          wrapper.classList.add(className);
        }
        image.classList.add('ObjectFit--active');
        wrapper.style['background-size'] = image.dataset.objectFit;
        wrapper.classList.add('ObjectFit-container');
        wrapper.style['background-image'] = `url(${src})`;
        parentEl.appendChild(wrapper);
        wrapper.appendChild(image);
      }
    }
  }
}
