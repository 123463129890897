import { LocatorEvent, PinClickEvent, CardClickEvent, PinHoverEvent } from './Events.js';
import { GoogleMapsEnterprise } from 'js/components/Maps/GoogleMapsEnterprise.js';
import { Maps } from 'js/components/Maps/index.js';

export class SearchMap extends GoogleMapsEnterprise {
  static initClass() {
    Maps.FactoryForProvider.Google = (data) => new this(data);
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.SearchMap";
    this.breakPoint = 992;
    this.pinClickCardToTop = true;
    this.pinHoverCardToTop = false;
    this.disablePinHover = false;
    this.enableInfoWindow = false;
  }

  mapOffset() {
    return {
      top: this.BUFFER,
      bottom: this.BUFFER,
      left: this.BUFFER,
      right: this.BUFFER
    };
  }

  preparePin(i, loc, m) {
    let pin = super.preparePin(...arguments);
    pin.isDefault = true;

    if (!this.constructor.disablePinHover) {
      pin.addListener('mouseover', () => {
        this.hoverHandler(true, loc, pin, i, m);
      });
      pin.addListener('mouseout', () => {
        this.hoverHandler(false, loc, pin, i, m);
      })
    }

    let changeColor = (needsHighlight) => {
      let hasHighlight = !pin.isDefault;
      if (needsHighlight && !hasHighlight) {
        pin.setIcon(this.iconImage(loc, i, 'white', 'black'));
        pin.isDefault = false;
        pin.setZIndex(1);
      } else if (!needsHighlight && hasHighlight) {
        pin.setIcon(this.iconImage(loc, i));
        pin.isDefault = true;
        pin.setZIndex(0);
      }
      else {}
        //do nothing
    };

    let needsHighlight = (e, type) => {
      let basicNeeds = e.detail.yextId === `js-yl-${loc.id}`;
      if (type !== 'hover') {
        return basicNeeds;
      }
      return basicNeeds && e.detail.active;
    }

    document.addEventListener(CardClickEvent.eventTypeName, (event) => {
      changeColor(needsHighlight(event));
    });

    document.addEventListener(PinClickEvent.eventTypeName, (event) => {
      changeColor(needsHighlight(event));
    });
    document.addEventListener(PinHoverEvent.eventTypeName, (event) => {
      changeColor(needsHighlight(event, 'hover'));
    });

    return pin;
  }

  clickHandler(loc, pin, index, map) {

    let target = `js-yl-${loc.id}`;
    document.dispatchEvent(new PinClickEvent(target));

    if (this.constructor.enableInfoWindow) {
      if (this.infowindow != null) {
        this.infowindow.close();
        this.infowindow = null;
      }

      let infoContentTemplate = document.querySelector(`#${target} > .js-infowindow`);
      if (infoContentTemplate == null) { return; }
      if (window.innerWidth >= this.constructor.breakPoint) {
        this.infowindow = new google.maps.InfoWindow({
          content: infoContentTemplate.innerText
        });
        this.infowindow.open(map, pin);
      } else {
        let mobileInfo = document.getElementById('js-mobile-info');
        mobileInfo.innerHTML = infoContentTemplate.innerText;
        map.panTo(pin.position);
        window.setTimeout((function() {
          let overlay = document.getElementById('js-overlay');
          overlay.classList.add('is-active');
          return overlay.classList.add('in');
        }), 20);
      }
    }
  }

  hoverHandler(active, loc, pin, index, map) {

    let target = `js-yl-${loc.id}`;
    document.dispatchEvent(new PinHoverEvent(target, active));
  }

  iconImage(loc, i, backgroundColor, textColor) {
    if (backgroundColor == null) { backgroundColor = 'black'; }
    if (textColor == null) { textColor = 'white'; }
    let iconUrl = `data:image/svg+xml;charset=utf8,${encodeURIComponent(this.svgPin(i+1, backgroundColor, textColor))}`;
    return {
      url: iconUrl,
      scaledSize: new google.maps.Size(28,38)
    };
  }
}
SearchMap.initClass();

let registerClickHandlers = function() {
  let results = document.getElementsByClassName('js-location-result');
  for (let result of Array.from(results)) {
    result.addEventListener('click', function() {
      if (!(window.innerWidth >= SearchMap.breakPoint)) { return; }
      document.dispatchEvent(new CardClickEvent(this.getAttribute('id')));
    });
    result.addEventListener('mouseenter', function() {
      if (!(window.innerWidth >= SearchMap.breakPoint)) { return; }
      document.dispatchEvent(new PinHoverEvent(this.getAttribute('id')));
    });
  }

  document.addEventListener(PinClickEvent.eventTypeName, function(e) {

    let id = e.detail.yextId;
    if (id == null) { return; }
    document.getElementById(id).classList.add('is-active');
    let toRemove = document.querySelectorAll(`.js-location-result.is-active:not(#${id})`);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-active');
    }
    if (SearchMap.pinClickCardToTop) {
      window.location.hash = `#${id}`;
    }
  });

  document.addEventListener(CardClickEvent.eventTypeName, function(e) {

    let id = e.detail.yextId;
    if (id == null) { return; }
    document.getElementById(id).classList.toggle('is-active');
    let toRemove = document.querySelectorAll(`.js-location-result.is-active:not(#${id})`);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-active');
    }
  });

  document.addEventListener(PinHoverEvent.eventTypeName, function(e) {

    let id = e.detail.yextId;
    if (id == null) { return; }
    document.getElementById(id).classList.add('is-active');
    let toRemove = document.querySelectorAll(`.js-location-result.is-active:not(#${id})`);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-active');
    }
    if (SearchMap.pinHoverCardToTop) {
      window.location.hash = `#${id}`;
    }
  });
};

let registerMapToggling = function() {
  let buttons = document.querySelectorAll('.js-result-toggle');
  for (var button of Array.from(buttons)) {
    button.addEventListener('click', function() {
      document.body.classList.toggle('map-visible');
      document.dispatchEvent(new Event('toggle-map'));
    });
  }

  document.addEventListener('toggle-map', function() {
    for (button of Array.from(buttons)) {
      let disabled = button.getAttribute('disabled');
      if (disabled != null) { button.removeAttribute('disabled'); }
      if ((disabled == null)) { button.setAttribute('disabled', ''); }
    }
  });
};

export const MapSetup = () => {
  registerClickHandlers();
  registerMapToggling();
}
