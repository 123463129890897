import { Modal } from 'js/components/Modal/Modal.js';
require('script-loader!.tmp/js/templates/common/modules/Forms.js');
require('script-loader!.tmp/js/templates/components/svg/icons.js');
require('script-loader!.tmp/js/templates/components/Modal/Modal-override.js');
require('script-loader!.tmp/js/templates/location/helpers.js');


export class EmailModal {

  constructor() {
    this.emailBtns = document.querySelectorAll('.js-hero-email, .js-contact-email, .js-staff-email');
  }

  init() {
    Modal.initModals();

    for (let i = 0; i < this.emailBtns.length; i++) {
      this.emailBtns[i].addEventListener('click', (evt) => {
        let buttonClass = this.emailBtns[i].dataset.buttonclass;
        let modalIdentifier = this.emailBtns[i].dataset.modalidentifier;
        let email = this.emailBtns[i].dataset.email;
        let recipient = this.emailBtns[i].dataset.recipient;
        let baseUrl = window.location.protocol + '//' + window.location.host + '/';
        let elite = this.emailBtns[i].dataset.elite;

        let formElem = location.helpers.email_modal({
          baseUrl: baseUrl,
          buttonClass: buttonClass,
          jsClasses: modalIdentifier,
          email: email,
          recipient: recipient,
          elite: elite
        });
        if (document.querySelectorAll('.' + modalIdentifier).length == 0) {
          let elemToAdd = document.createElement('div');
          elemToAdd.classList.add('js-modal-wrapper');
          elemToAdd.innerHTML = formElem;

          this.emailBtns[i].parentNode.appendChild(elemToAdd);

          new Modal(elemToAdd.children[0], true);
        } else {
          let modalEl = document.querySelector('.js-Modal');
          let modalTitle = document.querySelector('.js-modal-title');
          let formEl = document.querySelector('.js-form-iframe');
          modalTitle.innerText = `Send ${recipient} an Email`;
          formEl.src = `https://yext-suidb.formstack.com/forms/nationwide_agent_email_form?field68288601=${email}`;
          if (elite == 'true') {
            modalEl.classList.add('Modal--elite');
          } else {
            modalEl.classList.remove('Modal--elite');
          }
        }
      });
    }
  }
}
