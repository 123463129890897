import { BaseMap } from './BaseMap.js';
import { OptimizedResizeInstance } from 'js/components/Util/OptimizedResize.js';

export class LeafletBase extends BaseMap {
  constructor(args) {
    super(args);
    this.mapOptions = {
      zoomControl: !this.config.disableMapControl,
      scrollWheelZoom: false,
      attributionControl: 'bottomright'
    };
  }

  iconImage(loc, i) {
    return new L.Icon({
      iconUrl: super.iconImage(...arguments)
    });
  }

  preparePin(index, loc, map, latlong) {
    let markerConfig = {icon: this.iconImage(loc, index)};
    if ((markerConfig.icon.options != null ? markerConfig.icon.options.iconAnchor : undefined) == null) { console.warn("icon missing iconAnchor, pin might be mispositioned"); }
    let marker = L.marker(latlong, markerConfig);
    marker.on('click', () => {
      this.clickHandler(loc, marker, index, map);
    });
    return marker.addTo(map);
  }

  initializeMap() {
    console.error("initializeMap is not implemented");
  }

  prepareMap() {
    this.map = this.initializeMap();

    this.map.once('load', () => {
      return this.mapLoaded();
    });
    if (this.config.disableMapControl) {
      this.map.dragging.disable();
      this.map.touchZoom.disable();
      this.map.doubleClickZoom.disable();
      this.map.scrollWheelZoom.disable();
      if (this.map.tap) { this.map.tap.disable(); }
    }

    let markerPositions = [];

    let index = 0;
    for (let loc of Array.from(this.allLocations)) {
      let latlng = L.latLng(loc.latitude, loc.longitude);
      this.preparePin(index, loc, this.map, latlng);
      markerPositions.push(latlng);
      index++;
    }

    this.markerBounds = L.latLngBounds(markerPositions);

    if (this.allLocations.length > 1) {
      this.setMapBounds();
    } else if (this.allLocations.length === 1) {
      this.map.setView(markerPositions[0], this.config.zoom);
    } else {
      this.map.setView(L.latLng(this.latitude, this.longitude), 3);
    }

    let cb = () => {
      return this.setMapBounds();
    };
    OptimizedResizeInstance.on(cb);
    return this.map;
  }


  setMapBounds() {
    if (!(this.allLocations.length > 1)) { return; }
    let offset = this.mapOffset();
    this.map.fitBounds(this.markerBounds, {
      paddingTopLeft: [offset.left, offset.top],
      paddingBottomRight: [offset.right, offset.bottom]
    });
  }

  redrawMap() {
    return this.setMapBounds();
  }
}
